export default (url: string, width = 128, height = 128, disabled = false): string => {
  if (disabled)
    return url

  const imageProxyUrl = `https://img.giardino-online.com/insecure/rs:fill:${width}:${height}/q:80/plain/`

  if (url.includes('https://giardino.ams3.digitaloceanspaces.com/'))
    url = url.replace('https://giardino.ams3.digitaloceanspaces.com/', '')

  return imageProxyUrl + url
}
